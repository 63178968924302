import React from 'react'
import { Link } from 'react-router-dom';

function Footer({ className }) {
    return (
        <>
            <section className={`section_style footer_section${className ? ` ${className}` : ''}`}>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='footer_fone'>
                                <img src='/assets/image/footer_fone.svg' alt='footer fone' />
                            </div>
                            <div className='footer_border'></div>
                            <div className='d-md-flex align-items-md-center justify-content-md-between'>
                                <p className='footer_content'>© 2024 Fone Network | All rights reserved</p>
                                <div className='d-flex align-items-center gap-3 mt-md-0 mt-3'>
                                    <Link to={'/privacypolicy'} className='footer_content'>Privacy Policy</Link>
                                    <p className='footer_content'>|</p>
                                    <div className='d-flex align-items-center gap-2'>
                                        <a href='https://twitter.com/fone_network' target='_blank'>
                                            <img src='/assets/image/twitter.svg' alt='twitter' />
                                        </a>
                                        <a href='https://t.me/fonenetwork' target='_blank'>
                                            <img src='/assets/image/telegram.svg' alt='telegram' />
                                        </a>
                                        <a href='https://www.instagram.com/foneblockchain' target='_blank'>
                                            <img src='/assets/image/instagram.svg' alt='instagram' />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Footer;