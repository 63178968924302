import React, { useState, useEffect } from 'react'
import Header from '../components/Header'
import axios from 'axios'
import Footer from '../components/Footer'
import SearchBar from '../components/SearchBar'
import { NODE_URL } from '../utils/nodeURL'

function Explorer() {

  const [cardsData, setCardsData] = useState(null)

  const fetchCardsData = async () => {
    try {
      const res = await axios.get(`${NODE_URL}/get/counts`)
      setCardsData(res?.data || null)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    fetchCardsData()
  }, [])

  return (
    <>
      <Header className={'header_bg'} activeMenu={"EXPLORER"} />
      <div>
        <section className='section_style explorer_section'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-8'>
                <h1 className='explorer_title'>Fone Network <br /> Blockchain Explorer</h1>
                <SearchBar />
              </div>
            </div>
            <div className='explorer_video2'>
              <video autoPlay loop muted playsInline className='w-100'>
                <source src="/assets/image/video.mp4" />
              </video>
            </div>
          </div>
        </section>
        <div className='explorer_bg pb-md-5 pb-4'>
          <section className='wallet_services'>
            <div className='container'>
              <div className='services_card'>
                <div className='card_logo'>
                  <img src='/assets/image/logo_dark.svg' alt='logo' />
                </div>
                <p className='explorer_pera'>Browse the Fone Network custom blockchain and keep track of the network <br className='d-md-block d-none' /> performance and stability.</p>
                <div className='d-flex align-items-baseline gap-2'>
                  <p className='explorer_pera mt-0'>Fone Current price:</p>
                  <span className='fone_price'>$0.00417</span>
                </div>
                {/* <div className='services_btn'>
                  <Link to=''>
                    <span>explore Now</span>
                    <img src='/assets/image/arrow_right.svg' alr='icon' />
                  </Link>
                </div> */}
              </div>
            </div>
          </section>
          <section className='explorer_history explorer_space'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-3 col-md-4 col-6'>
                  <div className='services_card price_card'>
                    <p className='explorer_pera'>Circulating Supply</p>
                    <div className='price_content'>
                      <h3>{cardsData?.circulating ? Number(cardsData?.circulating)?.toLocaleString() : 0}</h3>
                    </div>
                  </div>
                </div>
                <div className='col-lg-3 col-md-4 col-6'>
                  <div className='services_card price_card'>
                    <p className='explorer_pera'>Staked Fone</p>
                    <div className='price_content'>
                      <h3>{cardsData?.staked ? Number(cardsData?.staked)?.toLocaleString() : 0}</h3>
                    </div>
                  </div>
                </div>
                <div className='col-lg-3 col-md-4 col-6 mt-md-0 mt-3'>
                  <div className='services_card price_card'>
                    <p className='explorer_pera'>Transactions</p>
                    <div className='price_content'>
                      <h3>{cardsData?.transactions ? Number(cardsData?.transactions)?.toLocaleString() : 0}</h3>
                    </div>
                  </div>
                </div>
                <div className='col-lg-3 col-md-4 col-6 mt-lg-0 mt-3'>
                  <div className='services_card price_card'>
                    <p className='explorer_pera'>Last Finalized Block</p>
                    <div className='price_content'>
                      <h3>{cardsData?.blocks ? Number(cardsData?.blocks)?.toLocaleString() : 0}</h3>
                    </div>
                  </div>
                </div>
                <div className='col-lg-3 col-md-4 col-6 mt-lg-4 mt-3'>
                  <div className='services_card price_card'>
                    <p className='explorer_pera'>Total Supply</p>
                    <div className='price_content'>
                      <h3>{cardsData?.total ? Number(cardsData?.total)?.toLocaleString() : 0}</h3>
                    </div>
                  </div>
                </div>
                <div className='col-lg-3 col-md-4 col-6 mt-lg-4 mt-3'>
                  <div className='services_card price_card'>
                    <p className='explorer_pera'>Burned Fone</p>
                    <div className='price_content'>
                      <h3>{cardsData?.burned ? Number(cardsData?.burned)?.toLocaleString() : 0}</h3>
                    </div>
                  </div>
                </div>
                <div className='col-lg-3 col-md-4 col-6 mt-lg-4 mt-3'>
                  <div className='services_card price_card'>
                    <p className='explorer_pera'>NFTs Created</p>
                    <div className='price_content'>
                      <h3>{cardsData?.nftsCreated ? Number(cardsData?.nftsCreated)?.toLocaleString() : 0}</h3>
                    </div>
                  </div>
                </div>
                <div className='col-lg-3 col-md-4 col-6 mt-lg-4 mt-3'>
                  <div className='services_card price_card'>
                    <p className='explorer_pera'>Total Holders</p>
                    <div className='price_content'>
                      <h3>{cardsData?.holders ? Number(cardsData?.holders)?.toLocaleString() : 0}</h3>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className='row mt-4'>
                <div className='col-xl-6 mb-xl-0 mb-4'>
                  <div className='blocks_card'>
                    <div className='card_header'>
                      <h3>Latest Blocks</h3>
                    </div>
                    <div className='card_body'>
                      <div className='d-md-flex align-items-center gap-4 latest_data_div'>
                        <div className='d-flex align-items-center gap-2'>
                          <div className='box'>
                            <img src='/assets/image/box.svg' alt="Box icon" />
                          </div>
                          <div className='table_data data_row pt-md-0 pt-1'>
                            <span>54365</span>
                            <span>16 secs ago</span>
                          </div>
                        </div>
                        <div className='pt-md-0 pt-2'>
                          <div className='d-flex gap-1 table_data'>
                            <p>Fee Recipient</p> <span>0x1f9...8e676</span>
                          </div>
                          <div className='d-md-block d-none'>
                            <div className='d-flex gap-1 table_data'>
                              <span>247 txns</span> <span>in 12 secs</span>
                            </div>
                          </div>
                        </div>
                        <div className='d-flex gap-2 align-items-center ms-md-auto pt-md-0 pt-1'>
                         
                          <div className='d-flex gap-1 table_data d-md-none d-block pt-md-0 pt-1'>
                            <span>247 txns</span> <span>in 12 secs</span>
                          </div>
                          <div className='fone_data ms-0'>
                            <p>0.09323 FONE</p>
                          </div>
                        </div>
                      </div>
                      <div className='d-md-flex align-items-center gap-4 latest_data_div'>
                        <div className='d-flex align-items-center gap-2'>
                          <div className='box'>
                            <img src='/assets/image/box.svg' alt="Box icon" />
                          </div>
                          <div className='table_data data_row pt-md-0 pt-1'>
                            <span>54365</span>
                            <span>16 secs ago</span>
                          </div>
                        </div>
                        <div className='pt-md-0 pt-2'>
                          <div className='d-flex gap-1 table_data'>
                            <p>Fee Recipient</p> <span>0x1f9...8e676</span>
                          </div>
                          <div className='d-md-block d-none'>
                            <div className='d-flex gap-1 table_data'>
                              <span>247 txns</span> <span>in 12 secs</span>
                            </div>
                          </div>
                        </div>
                        <div className='d-flex gap-2 align-items-center ms-md-auto pt-md-0 pt-1'>
                          
                          <div className='d-flex gap-1 table_data d-md-none d-block pt-md-0 pt-1'>
                            <span>247 txns</span> <span>in 12 secs</span>
                          </div>
                          <div className='fone_data ms-0'>
                            <p>0.09323 FONE</p>
                          </div>
                        </div>
                      </div>
                      <div className='d-md-flex align-items-center gap-4 latest_data_div'>
                        <div className='d-flex align-items-center gap-2'>
                          <div className='box'>
                            <img src='/assets/image/box.svg' alt="Box icon" />
                          </div>
                          <div className='table_data data_row pt-md-0 pt-1'>
                            <span>54365</span>
                            <span>16 secs ago</span>
                          </div>
                        </div>
                        <div className='pt-md-0 pt-2'>
                          <div className='d-flex gap-1 table_data'>
                            <p>Fee Recipient</p> <span>0x1f9...8e676</span>
                          </div>
                          <div className='d-md-block d-none'>
                            <div className='d-flex gap-1 table_data'>
                              <span>247 txns</span> <span>in 12 secs</span>
                            </div>
                          </div>
                        </div>
                        <div className='d-flex gap-2 align-items-center ms-md-auto pt-md-0 pt-1'>
                          
                          <div className='d-flex gap-1 table_data d-md-none d-block pt-md-0 pt-1'>
                            <span>247 txns</span> <span>in 12 secs</span>
                          </div>
                          <div className='fone_data ms-0'>
                            <p>0.09323 FONE</p>
                          </div>
                        </div>
                      </div>
                      <div className='d-flex align-items-center justify-content-center py-3'>
                        <Link to='' className='view_btn'>
                          <span>View All Blocks</span>
                          <img src='/assets/image/arrow_right.svg' alt="icon" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-xl-6'>
                  <div className='blocks_card'>
                    <div className='card_header'>
                      <h3>Latest Transactions</h3>
                    </div>
                    <div className='card_body'>
                      <div className='d-md-flex align-items-center gap-4 latest_data_div'>
                        <div className='d-flex align-items-center gap-2'>
                          <div className='box'>
                            <img src='/assets/image/transactions_icon.svg' alt="icon" />
                          </div>
                          <div className='table_data data_row pt-md-0 pt-1'>
                            <span>0x54f4a2eaf35...</span>
                            <span>16 secs ago</span>
                          </div>
                        </div>
                        <div className='pt-md-0 pt-2'>
                          <div className='d-flex gap-1 table_data'>
                            <p>From</p> <span>0x4838B106...B0BAD5f97</span>
                          </div>
                          <div className='d-md-block d-none'>
                            <div className='d-flex gap-1 table_data'>
                              <span>To 0xaF81031b...499d5De1E</span>
                            </div>
                          </div>
                        </div>
                        <div className='d-flex gap-2 align-items-center ms-md-auto pt-md-0 pt-1'>
                          
                          <div className='d-flex gap-1 table_data d-md-none d-block pt-md-0 pt-1'>
                            <span>247 txns</span> <span>in 12 secs</span>
                          </div>
                          <div className='fone_data ms-0'>
                            <p>0.09323 FONE</p>
                          </div>
                        </div>
                      </div>
                      <div className='d-md-flex align-items-center gap-4 latest_data_div'>
                        <div className='d-flex align-items-center gap-2'>
                          <div className='box'>
                            <img src='/assets/image/transactions_icon.svg' alt="icon" />
                          </div>
                          <div className='table_data data_row pt-md-0 pt-1'>
                            <span>0x54f4a2eaf35...</span>
                            <span>16 secs ago</span>
                          </div>
                        </div>
                        <div className='pt-md-0 pt-2'>
                          <div className='d-flex gap-1 table_data'>
                            <p>From</p> <span>0x4838B106...B0BAD5f97</span>
                          </div>
                          <div className='d-md-block d-none'>
                            <div className='d-flex gap-1 table_data'>
                              <span>To 0xaF81031b...499d5De1E</span>
                            </div>
                          </div>
                        </div>
                        <div className='d-flex gap-2 align-items-center ms-md-auto pt-md-0 pt-1'>
                          
                          <div className='d-flex gap-1 table_data d-md-none d-block pt-md-0 pt-1'>
                            <span>247 txns</span> <span>in 12 secs</span>
                          </div>
                          <div className='fone_data ms-0'>
                            <p>0.09323 FONE</p>
                          </div>
                        </div>
                      </div>
                      <div className='d-md-flex align-items-center gap-4 latest_data_div'>
                        <div className='d-flex align-items-center gap-2'>
                          <div className='box'>
                            <img src='/assets/image/transactions_icon.svg' alt="icon" />
                          </div>
                          <div className='table_data data_row pt-md-0 pt-1'>
                            <span>0x54f4a2eaf35...</span>
                            <span>16 secs ago</span>
                          </div>
                        </div>
                        <div className='pt-md-0 pt-2'>
                          <div className='d-flex gap-1 table_data'>
                            <p>From</p> <span>0x4838B106...B0BAD5f97</span>
                          </div>
                          <div className='d-md-block d-none'>
                            <div className='d-flex gap-1 table_data'>
                              <span>To 0xaF81031b...499d5De1E</span>
                            </div>
                          </div>
                        </div>
                        <div className='d-flex gap-2 align-items-center ms-md-auto pt-md-0 pt-1'>
                          
                          <div className='d-flex gap-1 table_data d-md-none d-block pt-md-0 pt-1'>
                            <span>247 txns</span> <span>in 12 secs</span>
                          </div>
                          <div className='fone_data ms-0'>
                            <p>0.09323 FONE</p>
                          </div>
                        </div>
                      </div>
                      <div className='d-flex align-items-center justify-content-center py-3'>
                        <Link to='' className='view_btn'>
                          <span>View All Transactions</span>
                          <img src='/assets/image/arrow_right.svg' alt="icon" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </section>
        </div>
      </div>

      {/* <div className='explorer_footer'>
              <p>© 2024 Fone Network | All rights reserved</p>
            </div> */}

      <Footer className={'white_bg'} />
    </>
  )
}

export default Explorer