export const formatTime = (time) => {
    if (!time) return '';

    const currentDate = new Date();
    const inputDate = new Date(time);
    const timeDifference = currentDate.getTime() - inputDate.getTime();

    const minute = 60 * 1000;
    const hour = minute * 60;
    const day = hour * 24;

    if (timeDifference < minute) {
        return "Just now";
    } else if (timeDifference < hour) {
        const minutes = Math.floor(timeDifference / minute);
        return `${minutes} Min Ago`;
    } else if (timeDifference < day) {
        const hours = Math.floor(timeDifference / hour);
        return `${hours} Hours Ago`;
    } else {
        const daysAgo = Math.floor(timeDifference / day);
        if (daysAgo <= 31) {
            return `${daysAgo} Days Ago`;
        } else {
            const formattedDate = `${inputDate.getDate().toString().padStart(2, '0')}-${(inputDate.getMonth() + 1).toString().padStart(2, '0')}-${inputDate.getFullYear()}`;
            return formattedDate;
        }
    }
}

export const getStatus = (time) => {
    if (time) {
        const givenTime = new Date(time);
        const currentTime = new Date();
        const difference = currentTime - givenTime;
        const requireDiff = 12 * 1000;  // 12 sec

        if (difference > requireDiff) {
            return "Confirmed";
        } else {
            return "Pending";
        }
    }
    else return '-'
}
