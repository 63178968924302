import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'

function PitchDeck() {
    return (
        <>
            <Header />

            <section className='section_style hero_section whitepaper_section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='d-lg-flex align-items-lg-center justify-content-between mb-5'>
                                <h1 className='hero_title'>Fone Network Pitch Deck</h1>
                                <a href='/assets/image/pdf/FoneNetworkDeck.pdf' target='_blank' className='btn submit_btn mt-lg-0 mt-3'
                                >Download Deck</a>
                            </div>
                            <div className='mb-3'>
                                <img src='/assets/image/pitchdeck/1.png' alt='fone network' />
                            </div>
                            <div className='mb-3'>
                                <img src='/assets/image/pitchdeck/2.png' alt='fone network' />
                            </div>
                            <div className='mb-3'>
                                <img src='/assets/image/pitchdeck/3.png' alt='fone network' />
                            </div>
                            <div className='mb-3'>
                                <img src='/assets/image/pitchdeck/4.png' alt='fone network' />
                            </div>
                            <div className='mb-3'>
                                <img src='/assets/image/pitchdeck/5.png' alt='fone network' />
                            </div>
                            <div className='mb-3'>
                                <img src='/assets/image/pitchdeck/6.png' alt='fone network' />
                            </div>
                            <div className='mb-3'>
                                <img src='/assets/image/pitchdeck/7.png' alt='fone network' />
                            </div>
                            <div className='mb-3'>
                                <img src='/assets/image/pitchdeck/8.png' alt='fone network' />
                            </div>
                            <div className='mb-3'>
                                <img src='/assets/image/pitchdeck/9.png' alt='fone network' />
                            </div>
                            <div className='mb-3'>
                                <img src='/assets/image/pitchdeck/10.png' alt='fone network' />
                            </div>
                            <div className='mb-3'>
                                <img src='/assets/image/pitchdeck/11.png' alt='fone network' />
                            </div>
                            <div className='mb-3'>
                                <img src='/assets/image/pitchdeck/12.png' alt='fone network' />
                            </div>
                            <div className='mb-3'>
                                <img src='/assets/image/pitchdeck/13.png' alt='fone network' />
                            </div>
                            <div className='mb-3'>
                                <img src='/assets/image/pitchdeck/14.png' alt='fone network' />
                            </div>
                            <div className='mb-3'>
                                <img src='/assets/image/pitchdeck/15.png' alt='fone network' />
                            </div>
                            <div>
                                <img src='/assets/image/pitchdeck/16.png' alt='fone network' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default PitchDeck