import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { formatTime, getStatus } from '../utils/commonFunctions'
import Copy from './Copy'

export default function TransactionTable({ totalTx = [], loading = false, setLoading }) {

  const [dispayedTx, setDisplayedTx] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const itemPerPage = 10

  useEffect(() => {
    if (totalTx?.length) {
      setDisplayedTx([...totalTx].reverse().slice(0, itemPerPage * currentPage))
      setLoading?.(false)
    }else{
      setDisplayedTx([])
    }
  }, [totalTx, currentPage])

  return (
    <div className='blocks_card mt-4'>
      <div className='card_body'>
        <div className='data_list d-flex align-items-md-center align-items-start'>
          <img src='/assets/image/data_icon.svg' alt="icon" />
          <p>Latest {dispayedTx.length} from a total of <span>{(totalTx?.length || 0)}</span> transactions</p>
        </div>
        <div className='table-responsive'>
          <table className='table hstory_table address_data mb-0'>
            <thead>
              <tr>
                <th>Transaction Hash</th>
                <th>Age</th>
                <th>From</th>
                <th>To</th>
                <th>Value</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {
                loading ? <tr><td className='text-center' colSpan={8}>Loading...</td></tr> :
                  dispayedTx?.length ?
                    dispayedTx?.map((i, index) =>
                      <tr key={index}>
                        <td>
                          <div className='block_data'>
                            <img src='/assets/image/eyes_icon.svg' alt="icon" />
                            <Link to={`/tx/${i.hash}`} className='font_gray'>
                              {i.hash?.substr(0, 15) + '...'}
                            </Link>
                          </div>
                        </td>
                        <td>{formatTime(i.timestamp)}</td>
                        <td>
                          <div className='block_data gap-2'>
                            <Link to={`/address/${i.sender}`} className='font_gray'>
                              {i.sender?.substr(0, 15) + '...'}
                            </Link>
                            <Copy value={i.sender} />
                          </div>
                        </td>
                        <td>
                          <div className='block_data gap-2'>
                            <Link to={`/address/${i.recipient}`} className='font_gray'>
                              {i.recipient?.substr(0, 15) + '...'}
                            </Link>
                            <Copy value={i.recipient} />
                          </div>
                        </td>
                        <td className='data_value'>{i.amount?.toFixed(2)} FONE</td>
                        <td className='data_value'>{getStatus(i.timestamp)}</td>
                      </tr>
                    )
                    : <tr><td className='text-center' colSpan={8}>No Data Found</td></tr>
              }
            </tbody>
          </table>
          {
            dispayedTx.length !== totalTx?.length &&
            <div className='text-center'>
            <div className='view_more' onClick={() => setCurrentPage(prev => (prev + 1))}>
              View More
            </div>
            </div>
          }
        </div>
      </div>
    </div>
  )
}
