import React, { useState } from 'react'
import copy from "copy-to-clipboard"

export default function Copy({ value }) {

  const [isCopied, setIsCopipied] = useState(false)

  const copyValue = () => {
    if (value) {
      copy(value)
      setIsCopipied(true)
      setTimeout(() => {
        setIsCopipied(false)
      }, 1000)
    }
  }

  return (
    <div className='copy_icon'>
      {isCopied && <span className='copied'>Copied!</span>}
      <img
        src='/assets/image/copy_icon.svg'
        alt="Copy"
        style={{ cursor: 'pointer' }}
        onClick={copyValue}
      />
    </div>
  )
}