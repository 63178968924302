import axios from 'axios'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { NODE_URL } from '../utils/nodeURL'

export default function SearchBar({ className, setShow }) {

  const [searchInput, setSearchInput] = useState('')
  const [isSearchNotFound, setIsSearchNotFound] = useState(false)
  const navigate = useNavigate()

  const fetchAddressData = async () => {
    if (searchInput.length !== 64) {
      setIsSearchNotFound(true)
      return
    }
    try {
      const res = await axios.get(`${NODE_URL}/address/${searchInput}`)
      if (res?.data) {
        setShow?.()
        navigate(`/address/${searchInput}`, { state: res.data })
      } else {
        setIsSearchNotFound(true)
      }
    } catch (err) {
      setIsSearchNotFound(true)
    }
  }

  const fetchTxData = async () => {
    try {
      const res = await axios.get(`${NODE_URL}/transaction/${searchInput}`)
      const resBlock = await axios.get(`${NODE_URL}/block/${res?.data?.block}`)
      if (res?.data?.transaction) {
        setShow?.()
        navigate(`/tx/${searchInput}`, { state: { transaction: res.data.transaction, block: resBlock?.data?.block } })
      } else if (resBlock?.data?.block) {
        navigate(`/block/${searchInput}`, { state: resBlock.data.block })
      } else {
        setIsSearchNotFound(true)
      }
    } catch (err) {
      fetchBlockData()
    }
  };

  const fetchBlockData = async () => {
    try {
      const res = await axios.get(`${NODE_URL}/block/${searchInput}`)
      if (res.data?.block) {
        setShow?.()
        navigate(`/block/${searchInput}`, { state: res.data?.block })
      }
      else
        fetchAddressData()
    } catch (err) {
      fetchAddressData()
    }
  }

  const handleSearch = () => {
    if (!searchInput) return
    if (searchInput.length == 64) fetchTxData()
    else if (!isNaN(searchInput)) fetchBlockData()
    else setIsSearchNotFound(true)
  }

  return (
    <div className={`explorer_select ${className || ''}`}>
      <input
        type="text"
        className="form-control form_search"
        placeholder="Search Address, Blocks or Transactions"
        autoFocus
        value={searchInput}
        onChange={e => {
          setSearchInput(e.target.value?.trim())
          setIsSearchNotFound(false)
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') handleSearch()
        }}
      />
      <button type='button' className='btn search_btn p-0' onClick={handleSearch}>
        <img src='/assets/image/search_icon.svg' alt='search' />
      </button>
      {isSearchNotFound && <p className='explorer_pera search_msg'>No search result found</p>}
    </div>
  )
}
